

export const Nosotros = () => {


    return (
        <div className="container my-5">
            <h2>Nosotros</h2>
            <hr />
        </div>
    )
}